.ButtonLittle{
  font-family: 'Roboto', sans-serif;
  background-color: transparent;
  font-size: 1.4rem;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3rem;
  color: #BF5F13;
  border: 1px solid #BF5F13;
  white-space: nowrap;

  &:hover{
    background-color: white;
  }

  &:disabled{
    cursor: default;
    opacity: 50%;
    &:hover{
      background-color: transparent;
    }
  }
}