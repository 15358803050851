//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.SubMenuButton{
  font-family: "Manrope", sans-serif;
  margin-bottom: 1rem;
  width: 100%;
  cursor: pointer;
  border: none;
  text-align: left;
  padding: 1.2rem 2rem 1.2rem 2rem;
  box-sizing: border-box;
  font-size: 1.6rem;
  background-color: transparent;
  &:hover{
    background-color: rgb(201, 201, 201);
  }
  @include min(1000){
    padding: 1.2rem 1.6rem 1.2rem 1.6rem;
  }
}

.selected .SubMenuButton{
  background-color: rgb(201, 201, 201);
}