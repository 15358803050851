.mini_select_wrapper{
  border: none;
  position: relative;
  width: fit-content;
  &:hover{
    .select_display_text{
      background-color: white!important;
    }
  }
}

.select{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  opacity: 0;
}

.select:focus{
  outline: 1px solid black;
  -webkit-appearance: none;
}

.select_display_text{
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  color: #BF5F13;
  box-shadow: 0 0 0 1px #BF5F13 inset, 0 0 0 0 transparent;
  background-color: transparent;
  border: none;
  width: 10.5rem;
  height: 3rem;
  line-height: 3rem;
  padding-left: 1rem;
  display: inline-flex;
  flex-direction: row;
  position: relative;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  text-overflow: ellipsis;

  .select_chevron{
    position: absolute;
    right: 0.7em;
    top: 1.2rem;
    height: 0.8rem;
    width: 1.2rem;
    img{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}