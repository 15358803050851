//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.Button{
  font-family: "Manrope", sans-serif;
  font-size: 1.4rem;
  display: block;
  cursor: pointer;
  padding: 0.5em 0.8em 0.5em 0.8em;
  color: transparent;
  border: 2px solid #ff8c00;
  position: relative;
  background-color: white;
  white-space: nowrap;
  width: fit-content;

  .text{
    font-family: "Manrope", sans-serif;
    font-size: 1.4rem;
    color: #ff8c00;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover{
    .text{
      font-weight: 500;
      color: white;
      background-color: #ff8c00;
    }
  }
}

.right{
  margin-left: auto;
  transform: translate(0, -50%);
  @include min(1090){
    margin-left: 0;
    margin-top: 2rem;
  }
}

.top_right{
  position: absolute;
  top: 2rem;
  right: 3rem;
}

.green{
  padding: 0.5em 1em 0.5em 1em;
  border: 2px solid #1ebc30;
  margin-left: 3rem;

  .text{
    font-weight: 500;
    color: #1ebc30;
  }

  &:hover{
    .text{
      font-weight: 500;
      color: white;
      background-color: #1ebc30;
    }
  }
}