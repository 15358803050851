.Notifications{
  font-size: 1.4rem;
  padding: 2.5rem;
  line-height: 1.6em;

  .title{
    font-family: "Manrope", sans-serif;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .notification,
  .notification_read{
    font-size: inherit;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    background-color: white;
    line-height: 1.8rem;
    display: block;
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin: 0.2rem -1rem 0.25rem -1rem;
    text-align: left;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    color: #0d00ff;
    &:hover{
      background-color: rgb(201, 201, 201);
    }
  }

  .notification_read{
    color: black;
    font-weight: normal;
  }
}