//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.problem{
  padding: 2rem;
  background-color: white;
  font-size: 2rem;
  line-height: 1.4em;
  border-radius: 4px;
  @include min(600){
    font-size: 1.8rem;
  }
  @include min(550){
    font-size: 1.6rem;
    padding: 15px;
  }
}