//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.FilterButton{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
  display: block;
  cursor: pointer;
  padding: 0.9em 1.5em 0.9em 1.5em;
  color: transparent;
  border: 2px solid #ff8c00;
  position: relative;
  background-color: transparent;
  white-space: nowrap;
  width: fit-content;
  border-radius: 100px;
  margin-right: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  @include min(600){
    padding: 0.7em 1.3em 0.7em 1.3em;
  }

  .text{
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    color: black;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
  }

  &:hover{
    .text{
      font-weight: 500;
      color: white;
      background-color: #ff8c00;
    }
  }
}

.selected{
  cursor: default;
  .text{
    font-weight: 500;
    color: white;
    background-color: #ff8c00;
  }
}