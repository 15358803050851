//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.Section{
  padding: 2rem 3rem 2.5rem 3rem;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 3rem;
  position: relative;
  @include min(750){
    padding: 2rem;
  }
  @include min(550){
    padding: 15px;
  }
  &:last-of-type{
    margin-bottom: 0;
  }

  .body{
    font-size: 1.6rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .title{
    font-weight: 700;
    font-size: 2.6rem;
    margin-top: 0.5rem;
    margin-bottom: 1em;
    @include min(750){
      font-size: 2.2rem;
    }
  }

  p{
    margin-top: -0.75em;
    margin-bottom: 1em;
    line-height: 1.5em;
    @include min(1090){
      width: 100%;
    }
  }
}

.SectionAppWrap{
  .body{
    display: block;
    margin-left: -3rem;
    margin-right: -3rem;
    @include min(750){
      margin-left: -2rem;
      margin-right: -2rem;
    }
    @include min(550){
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  p{
    display: block;
    margin-left: 3rem;
    margin-right: 3rem;
    width: auto;
    @include min(750){
      margin-left: 2rem;
      margin-right: 2rem;
    }
    @include min(550){
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.SectionTableWrap{
  @include min(550){
    padding: 15px 0 15px 15px;
  }
}