//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.TempMessage{
  color: #1ebc30;
  box-shadow: 0 0 0 4px #1ebc30 inset, 0 0 0 0 transparent;
  background-color: white;
  padding: 1rem 3rem 1.5rem 3rem;
  border-radius: 4px;
  margin-bottom: 3rem;
  position: relative;
  @include min(550){
    padding: 1rem 15px 15px;
  }

  .title{
    font-weight: 700;
    font-size: 2.4rem;
    margin-top: 0.5rem;
  }

  .messageContainer{
    font-size: 1.6rem;
    position: relative;
    padding-right: 100px;
    margin: 10px 0 10px;
    @include min(550) {
      padding-right: 0;
      padding-bottom: 50px;
    }

    button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);

      @include min(550) {
        left: 0;
        right: auto;
        top: auto;
        bottom: -4px;
        transform: none;
        margin: 0;
      }
    }
  }
}