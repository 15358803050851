.MoreButton{
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  color: #BF5F13;
  box-shadow: 0 0 0 2px #BF5F13 inset, 0 0 0 0 transparent;
  background-color: transparent;
  border: none;
  padding: 0.45em 1.9em 0.45em 1em;
  display: inline-flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  white-space: nowrap;

  span{
    position: absolute;
    right: 0.65em;
    top: 51%;
    transform: translate(0, -50%);
    margin: 0;

    img{
      display: block;
      width: 0.7em;
      height: 0.9em;
    }

  }

  &:hover{
    background-color: white;
  }
}