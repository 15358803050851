.PlusToArrow{
  display: inline-block;
  height: 17px;
  width: 17px;
  position: relative;
  margin-left: 10px;
  margin-bottom: 1px;
  transition: transform 200ms;

  img{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: opacity 200ms;
  }

  .plus{
    opacity: 1;
  }

  .arrow{
    transform: rotate(135deg);
    opacity: 0;
  }
}

.PlusToArrowOpen{
  transform: rotate(-135deg);

  .plus{
    opacity: 0!important;
  }

  .arrow{
    opacity: 1!important;
  }
}