//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.ContactPreferencesPanel{
  font-size: 1.6rem;
  line-height: 1.4em;
  font-weight: normal;

  .mailing_item{
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    border-bottom: 1px solid #CCCCCC;
    font-size: 1.6rem;
    @include min(400){
      font-size: 1.4rem;
      line-height: 1.4em;
    }

    &:last-of-type{
      border-bottom: none;
    }

    .title{
      padding-bottom: 0.3rem;
      font-size: 1.8rem;
      font-weight: 500;
    }
  }
}