//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.BurgerMenuButton{
  height: 2.4rem;
  width: 3.5rem;
  cursor: pointer;
  border: none;
  background-repeat: no-repeat;
  background-color: transparent;
  @include min(850){
    height: 2.2rem;
    width: 3.3rem;
  }
  @include min(500){
    height: 2.1rem;
    width: 3.2rem;
  }
}

.mobMenu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  pointer-events: none;

  &_background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: background-color 400ms;
  }

  &_tray {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 33.333vw;
    background-color: #4d0c00;
    padding: 2rem;
    transform: translate(-100%);
    transition: transform 400ms, visibility 0ms 400ms;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: flex-start;
    @include min(1100){
      width: 50vw;
    }
    @include min(900){
      width: 80vw;
    }
    @include min(500){
      width: 100vw;
    }

    &_close {
      position: absolute;
      top: 1.5rem;
      right: 3rem;
      height: 3rem;
      cursor: pointer;
      background-color: transparent;
      border: none;
      color: white;
      padding: 0;
      margin: 0;
      font-family: "Manrope", sans-serif;
      font-size: 2rem;
      display: block;
      @include min(500){
        right: 1.5rem;
      }

      img{
        display: inline-block;
        height: 2.5rem;
        width: 2.5rem;
      }
    }

    nav {
      display: flex;
      flex-direction: column;
      margin: auto auto auto 20%;
      @include min(500){
        margin: auto auto auto 12vw;
      }

      .bigLink {
        font-family: "Manrope", sans-serif;
        display: block;
        text-align: left;
        cursor: pointer;
        color: white;
        border: none;
        font-size: 3rem;
        padding: 0.4rem 0 0.4rem 0;
        background-color: transparent;
      }

      .smallLink {
        display: block;
        padding: 0.15rem 0 0.15rem 0;
        background-color: transparent;
        color: white;
        opacity: 0.8;
        font-size: 1.8rem;
        margin-left: 3rem;

        &:first-of-type {
          margin-top: 1.5rem;
        }

        &:last-of-type {
          margin-bottom: 2rem;
        }

        &:hover {
          opacity: 1;
        }
      }

      .linkActive{
        opacity: 1;
        cursor: default;
      }
    }
  }
}

.openMenu {
  pointer-events: auto;

  .mobMenu_background {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .mobMenu_tray {
    transition: transform 400ms, visibility 0ms;
    visibility: visible;
    transform: translate(0);
  }
}