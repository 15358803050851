.Account{

  .inner{
    padding: 2.5rem 2.5rem 0 2.5rem;

    .title{
      color: black;
      font-family: "Manrope", sans-serif;
      font-size: 2rem;
      display: block;
      margin-bottom: 1.5rem;
    }

    .link{
      color: black;
      font-size: 1.5rem;
      border: none;
      cursor: pointer;
      display: inline-block;
      margin: 0;
      padding: 0.25em 0 0.25em 0;
      border-bottom: 1px solid transparent;
      &:hover{
        border-bottom: 1px solid black;
      }
    }
  }

  .sign_out{
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
    font-size: 1.6rem;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    margin-top: 2rem;
    display: block;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: left;
    border-top: solid 1px #CCCCCC;
    font-weight: 500;
    color: black;
  }
}