//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.history_container{
  min-height: 30vh;
  border: 1px solid black;
  padding: 2.5rem 3rem 2.5rem 3rem;
  position: relative;
  background-color: white;
  @include min(550){
    padding: 20px 15px 20px 15px;
  }

  .history_item{
    font-size: 1.6rem;
    padding-top: 0.35em;
    padding-bottom: 0.35em;
    line-height: 1.4em;
    @include min(550){
      font-size: 1.5rem;
    }
    @include min(400){
      font-size: 1.4rem;
    }
  }
}