.TopBarIcon{
  height: 3.5rem;
  width: 3.5rem;
  background-color: #8C1500;
  border-radius: 100px;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  border: none;

  img{
    position: absolute;
    height: 1.75rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .letter{
    font-family: "Manrope", sans-serif;
    font-size: 1.7rem;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .alert{
    height: 10px;
    width: 10px;
    position: absolute;
    background-color: #ff8c00;
    border-radius: 20px;
    top: 0;
    right: 0;
    display: block;
  }

  &:hover{
    background-color: #BF4833;
  }
}

.on{
  background-color: #4B4B4B;

  &:hover{
    background-color: #4B4B4B;
  }
}