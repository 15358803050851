@import "_fonts.css";

//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

html, body{
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
  font-size: 62.5%;
  background-color: #eee;
  @include min(330){
    font-size: 9px;
  }
}

body.modal-open{
  overflow: hidden;
}

b{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

h1, h2, h3{
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  margin: 0;
  line-height: 1.4em;
}

h2{
  @include min(750){
    font-size: 2rem;
  }
}

/* Form */
.form{
  background-color: white;
  padding: 4rem;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
  @include min(500){
    padding: 3rem;
  }
  @include min(400){
    padding: 20px;
  }

  .field{
    width: 100%;
    margin-bottom: 2rem;
    display: block;
    position: relative;

    &--half{
      width: calc(50% - 1rem);
      @include min(560){
        width: 100%;
      }
    }

    .label{
      font-family: 'Manrope', sans-serif;
      font-size: 1.8rem;
      display: block;
      padding-bottom: 1rem;
      white-space: nowrap;
    }

    .input,
    .react-datepicker__input-container input{
      font-family: 'Roboto', sans-serif;
      padding-left: 1rem;
      padding-right: 1rem;
      width: 100%;
      height: 4.5rem;
      box-sizing: border-box;
      display: block;
      border: 1px solid #757675;
      font-weight: normal;
      border-radius: 0;
      font-size: 18px;
      @include min(330){
        font-size: 16px;
      }
      &:focus{
        border: 1px solid #ff8c00;
        outline: 2px solid #ff8c00;
      }
      &:disabled{
        -webkit-text-fill-color: #757675!important;
        color: #757675;
        outline: solid 1px #757675;
        opacity: 0.8!important;
      }
    }
  }

  .forgotten_link{
    font-weight: normal;
    font-size: 1.4rem;
    padding-bottom: 0.3em;
    margin: auto;
    margin-top: 4rem;
    display: inline-block;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    color: #0d00ff;
    &:hover{
      border-bottom: 1px solid #0d00ff;
    }
  }
}

.submit {
  font-family: 'Manrope', sans-serif;
  font-size: 1.8rem;
  border: none;
  display: block;
  height: 4.25rem;
  padding-bottom: 0.25rem;
  width: 100%;
  background-color: black;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #757675;
  }

  &:focus {
    outline: 3px solid #ff8c00;
    -webkit-appearance: none;
  }

  &:disabled,
  &[disabled]{
    pointer-events: none;
    cursor: default;
  }

  &--alt {
    color: black;
    margin-top: 5px;
    background-color: transparent;

    &:hover {
      color: #757675;
      background-color: transparent;
    }

    &:focus {
      outline: 3px solid #ff8c00;
      -webkit-appearance: none;
    }

    &:disabled,
    &[disabled]{
      pointer-events: none;
      cursor: default;
    }
  }
}

.bubble{
  color: #db2828;
  line-height: 2rem;
  background-color: #ffe8e6;
  position: absolute;
  font-weight: normal;
  font-size: 1.4rem;
  padding: 0.6em 1.2em 0.7em 1.2em;
  margin: 1em 0 3em;
  border: 2px solid #db2828;
  bottom: 8px;
  right: 0;
  max-width: 220px;
  width: 100vw;

  &:after {
    content: "";
    position: absolute;
    height: 18px;
    width: 18px;
    border-bottom: 2px solid #db2828;
    border-left: 2px solid #db2828;
    bottom: 0;
    left: 20px;
    transform: translate(0, 57%) rotate(-45deg);
    background-color: #fae7ec;
  }
}

.ReturnMsg{
  color: #1ebc30;
  box-shadow: 0 0 0 2px #1ebc30 inset, 0 0 0 0 transparent;
  background-color: #e5f9e7;
  padding: 2rem;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.2em;
  margin-bottom: 2rem;
}

.ReturnMsgError{
  color: #db2828;
  box-shadow: 0 0 0 2px #db2828 inset, 0 0 0 0 transparent;
  background-color: #ffe8e6;
}

.sending{
  position: absolute;
  background-color: #eee;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
}

tr:nth-child(even) {background-color: #f2f2f2;}

/* General Shared */
.app_width{
  max-width: 1300px;
  width: 90%;
  margin: auto;
  @include min(550){
    width: calc(100% - 30px);
  }
}

.members_area{
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .members_area_body{
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}

.two_columns{
  display: flex;
  flex-direction: row;
  @include min(780){
    flex-wrap: wrap;
    flex-direction: column;
  }

  .left_column{
    flex: 1;
    max-width: 77%;
    @include min(780){
      display: block;
      width: 100%;
      max-width: none;
      margin-left: 0;
    }
  }
}

.page{
  background-color: white;
  margin-bottom: 3rem;
  padding: 2rem 4rem 6rem 4rem;
  font-size: 1.6rem;
  line-height: 1.4em;
  @include min(850){
    padding: 2rem 3rem 4rem 3rem;
  }
  @include min(550){
    padding: 15px;
  }
}

.hide-text{
  color: transparent!important;
  font-size: 0!important;
  line-height: 0!important;
}

.scrollable{
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: auto !important;
}

a{
  text-decoration: none;
}

/* Login Pages */
.login_container{
  margin: auto;
  width: 70vw;
  max-width: 700px;
  margin-top: 10vh;
  text-align: center;
  @include min(830){
    width: calc(100% - 30px);
  }

  .login_box{
    margin-top: 3rem;
    margin-bottom: 3rem;
    background-color: white;
    position: relative;

    .login_links{
      display: flex;
      flex-direction: row;
      width: calc(100% - 5rem);
      margin: auto;
      margin-bottom: 2rem;
      position: relative;

      .login_divide{
        position: absolute;
        left: 50%;
        top: 2.5rem;
        bottom: 2.5rem;
        width: 1px;
        background-color: #CCCCCC;
        transform: translate(-50%, 0);
      }

      .login_link{
        font-family: "Manrope", sans-serif;
        font-weight: normal;
        font-size: 1.6rem;
        flex: 1;
        display: block;
        padding: 4.5rem 0 3.5rem 0;
        color: #757675;
        border-bottom: 1px solid #CCCCCC;
        @include min(450){
          padding: 3.5rem 0 2.5rem 0;
        }
      }

      .login_link_selected{
        color: black;
        border-bottom: 2px solid #0d00ff;
      }
    }

    h1{
      margin: auto;
      width: calc(100% - 5rem);
      font-family: "Manrope", sans-serif;
      font-size: 2.6rem;
      padding-top: 4rem;
      padding-bottom: 3rem;
      border-bottom: 1px solid #CCCCCC;
      @include min(560){
        padding-top: 3rem;
        padding-bottom: 2.5rem;
      }
      @include min(400){
        font-size: 2.2rem;

      }
    }

    h2{
      font-family: "Manrope", sans-serif;
      font-size: 2.2rem;
      padding-top: 4rem;
      padding-bottom: 1rem;
    }

    p{
      font-size: 1.6rem;
      line-height: 1.6em;
      width: 60%;
      margin: auto;
      @include min(750){
        width: 80%;
      }
      @include min(650){
        width: calc(100% - 5rem);
      }
    }
  }
}