//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.Footer{
  background-color: #4d0c00;
  margin-top: 10rem;
  flex: 1;
  color: white;
  @include min(700){
    margin-top: 10vw;
  }

  .columns_container{
    margin-top: 10rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    @include min(730){
      margin-top: 5vw;
    }

    .column{
      width: 25%;
      @include min(820){
        width: 33.333%;
      }
      @include min(730){
        width: 50%;
        margin-top: 4rem;
      }
      @include min(470){
        width: 100%;
      }

      .title{
        display: table;
        margin-right: auto;
        padding-bottom: 0.3em;
        font-family: "Manrope", sans-serif;
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 1.5rem;
        border-bottom: solid 2px #ff8c00;
      }

      .link{
        font-family: 'Roboto', sans-serif;
        font-size: 1.6rem;
        font-weight: normal;
        line-height: 2em;
        color: rgba(255, 255, 255, 0.7);
        border-bottom: 1px solid transparent;
        &:hover{
          color: rgba(255, 255, 255, 1);
          border-bottom: 1px solid white;
        }
      }
    }

    .social_container{
      margin-top: 3rem;
      margin-bottom: 3rem;
      display: flex;
      flex-direction: row;

      .social_icon{
        background-color: rgba(255, 255, 255, 0.2);
        height: 4rem;
        width: 4rem;
        margin-right: 1.25rem;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
          background-color: rgba(255, 255, 255, 0.3);
        }

        img{
          max-height: 80%;
          max-width: 80%;
        }
      }
    }
  }

  .web_address{
    font-size: 1.6rem;
    color: white;
    border-bottom: 1px solid transparent;
    &:hover{
      border-bottom: 1px solid white;
    }
  }

  .footer_info{
    color: white;
    line-height: 2rem;
    font-size: 1.6rem;
    text-align: right;
    margin-bottom: 12rem;
    @include min(820){
      margin-top: 3rem;
    }
    @include min(730){
      margin-bottom: 12vw;
    }
    @include min(470){
      text-align: left;
    }
  }

  .divide{
    height: 1.2rem;
    width: 2px;
    margin-left: 2rem;
    margin-right: 2rem;
    display: inline-block;
    background-color: #ff8c00;
  }
}