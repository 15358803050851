//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

@mixin max($width){
  @media (min-width: $width + px){
    @content
  }
}

@mixin certificate($c-unit){
  .certificate {
    font-family: "Playfair Display", "Times New Roman", Times, serif;
    font-size: (2.5 * $c-unit);
    position: relative;
    width: 100%;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 5px 0 rgba(0, 0, 0, 0.15);

    &__paper {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
    }

    &__paper-logo {
      position: absolute;
      top: 18%;
      left: 0;
      width: 100%;

      &--lower {
        top: 25%;
      }
    }

    &__logo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    &__stamp {
      position: absolute;
      right: 9%;
      top: 27%;
      width: 11.5%;
    }

    &__border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      &__top {
        background-size: 100%;
        height: 0;
        padding-bottom: 21.6%;
      }

      &__middle {
        background-size: 100%;
        flex: 1;
      }

      &__bottom {
        background-size: 100%;
        height: 0;
        padding-bottom: 21.6%;
        background-position: bottom;
      }
    }

    &__inner {
      position: relative;
      width: 100%;
      padding-top: (24 * $c-unit);
      padding-bottom: (6 * $c-unit);
      display: flex;
      flex-direction: column;
      align-items: center;

      &__badc {
        color: #730E16;
        font-size: (4 * $c-unit);
      }

      &__name {
        font-family: "Calligraffitti", "Brush Script MT", "Apple Chancery", Trattatello, cursive;
        text-align: center;
        font-weight: bold;
        font-size: (4 * $c-unit);
        margin-top: (3.5 * $c-unit);
        margin-bottom: (2 * $c-unit);
      }

      &__weapons-system {
        font-weight: 900;
        max-width: 46%;
        text-align: center;
        line-height: 1.2em;
        font-size: (4.5 * $c-unit);
      }

      &__awarded-on {
        margin-top: (3.4 * $c-unit);
        margin-bottom: (2.6 * $c-unit);
      }

      &__has-achieved {
        margin-top: (1.3 * $c-unit);
      }

      &__consolidation-banner {
        margin-top: (5 * $c-unit);
        margin-bottom: (6 * $c-unit);
        width: 65%;
      }

      &__record-of-training {
        text-transform: uppercase;
      }

      &__course {
        font-weight: 900;
        margin-top: (1 * $c-unit);
        font-size: (3.5 * $c-unit);
        width: (45 * $c-unit);
        height: (8.9 * $c-unit);
        padding-bottom: (0.4 * $c-unit);
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;
        flex-wrap: wrap;

        &:first-of-type {
          margin-top: (1.5 * $c-unit);
        }

        &:last-of-type {
          margin-bottom: (2 * $c-unit);
        }

        &__covid-text {
          font-size: (2.4 * $c-unit);
          width: 100%;
          margin-top: calc(0px - (1 * $c-unit));
          display: block;
        }
      }

      &__details {
        text-align: center;
        margin-bottom: (0.7 * $c-unit);

        b {
          font-family: "Playfair Display", "Times New Roman", Times, serif;
          font-weight: 600;
        }
      }

      &__expiry-date {
        background-size: 100% 100%;
        padding: (4.5 * $c-unit) (5 * $c-unit) (5 * $c-unit) (5 * $c-unit);
      }

    }

    &__shield {
      position: absolute;
      left: 8%;
      top: 41%;
      width: 16%;

      img {
        width: 100%;
      }

      &__text {
        position: absolute;
        top: 22%;
        left: 15%;
        width: 70%;
        text-align: center;
        font-size: (2 * $c-unit);

        b {
          display: block;
          margin-top: (1 * $c-unit);
          font-family: "Playfair Display", "Times New Roman", Times, serif;
          font-weight: 600;
        }

        &__stars {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: (2 * $c-unit);

          img {
            width: (2 * $c-unit);
          }
        }

      }
    }

    &__grade-badge {
      position: absolute;
      right: 8%;
      top: 41%;
      width: 18%;

      img {
        width: 100%;
      }

      &__text {
        position: absolute;
        top: 50%;
        left: 10%;
        width: 80%;
        text-align: center;
        font-size: (2.2 * $c-unit);
        font-weight: 600;
        transform: translate(0, -68%);
      }
    }

  }
}

// General certificate
@include certificate(7.2px);

// Flexible certificate 840
@include min(840) {
  @include certificate(0.86vw);
}

// Flexible certificate 600
@include min(600) {
  @include certificate(0.9vw);
}

// Narrower certificate width on member courses page
.narrower-certificate-width {
  @include min(1450) {
    @include certificate(0.499vw);
  }
  @include min(780) {
    @include certificate(0.79vw);
  }
  @include min(550) {
    @include certificate(0.8vw);
  }
}

.certificate--with-margins{
  margin-top: 2rem;
  margin-bottom: 3rem;
  @include min(600){
    margin-bottom: 15px;
    margin-top: 20px;
  }
  &:last-of-type{
    margin-bottom: 0;
  }
}

//Print style
@media print {

  body,html {
    background-color: white!important;
  }

  @page {
    margin: 0;
    background-color: white;
  }

  // General certificate
  @include certificate(8.3333px);

  .certificate {
    box-shadow: none;
    width: 1000px;
    overflow: hidden;
    background-color: white;
  }

  .certificate__paper {
    display: none!important;
  }
}