//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.SubMenu{
  width: 20%;
  display: flex;
  margin-top: 4rem;
  flex-direction: column;
  @include min(1150){
    width: 25%;
  }
  @include min(860){
    display: none;
  }
}