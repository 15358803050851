//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.PageTitle{
  font-family: "Manrope", sans-serif;
  font-size: 3.4rem;
  margin-top: 3.5rem;
  margin-bottom: 3rem;
  @include min(750){
    font-size: 3rem;
  }
  @include min(600){
    font-size: 2.7rem;
  }
  @include min(500){
    font-size: 2.6rem;
  }
}