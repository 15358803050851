.iris_wipe{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw * 2);
  height: calc(100vw * 2);
  border-radius: calc(100vw * 2);
  background-color: transparent;
  outline: calc((100vw * 5)) solid black;
  pointer-events: none;
  z-index: 9999;
  animation: animateIris forwards 1s 2s;
}

@keyframes animateIris {
  from{
    width: calc(100vw * 2);
    height: calc(100vw * 2);
    border-radius: calc(100vw * 2);
  }
  to{
    width: 0;
    height: 0;
    border-radius: 0;
  }
}