//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.courseAdminWrapper{
  display: flex;
  flex-direction: row;
  margin-left: auto;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 100%;
  @include min(1090){
    width: 100%;
  }

  a{
    min-width: calc(16.6666% - 1px);
    @include min(1090){
      min-width: calc(16.6666% - 1px);
    }
  }
}