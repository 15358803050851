//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.percent_wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.percent_input{
  font-family: 'Roboto', sans-serif;
  text-align: right;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 7rem;
  height: 3rem;
  box-sizing: border-box;
  display: block;
  outline: solid 1px #757675;
  outline-offset: -1px;
  border: none;
  font-weight: normal;
  -webkit-appearance: none;
  font-size: 16px;

  &:focus,
  select:focus{
    outline: 3px solid #ff8c00;
    -webkit-appearance: none;
  }
}

.percent_icon{
  margin-left: 0.7rem;
}