//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.RemoveButton{
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  color: #db2828;
  box-shadow: 0 0 0 2px #db2828 inset, 0 0 0 0 transparent;
  background-color: #ffe8e6;
  border: none;
  padding: 0.45em 2.1em 0.45em 1em;
  display: inline-flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  width: fit-content;

  span{
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translate(0, -48%);
    margin: 0;

    img{
      display: block;
      width: 1.4em;
      height: 1.4em;
    }

  }

  &:hover{
    background-color: white;
  }
}

.top_right{
  position: absolute;
  right: 0;
  top: 4.5rem;
  @include min(750){
    top: 4rem;
  }
}