.MemberProfile{

  h1{
    display: inline;
    margin-right: 1rem;
  }

  .line{
    height: 1px;
    background-color: #CCCCCC;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .tcr{
    font-weight: 500;
    padding-top: 1rem;
    display: inline-block;
  }

  h2{
    font-family: "Manrope", sans-serif;
    margin-top: 2rem;
    font-size: 2.2rem;
  }

  .profile_item{
    margin-top: 1rem;
    .label{
      font-family: "Manrope", sans-serif;
    }
  }

  table{
    margin-top: 1.5rem;

    tr{
      td:last-of-type{
        padding: 0;
        text-align: center;
        width: 33%;
      }
    }

  }

  .contact_pref_no{
    color: #db2828;
    box-shadow: 0 0 0 2px #db2828 inset, 0 0 0 0 transparent;
    background-color: #ffe8e6;
    height: 2rem;
    width: 2rem;
    margin: auto;
  }

  .contact_pref_yes{
    color: #1ebc30;
    box-shadow: 0 0 0 2px #1ebc30 inset, 0 0 0 0 transparent;
    background-color: #e5f9e7;
    height: 2rem;
    width: 2rem;
    margin: auto;
  }

}