//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.AccountSidePanel{
  width: calc(33.33% - 4rem);
  margin-left: 4rem;
  @include min(900){
    width: 24%;
    margin-left: 3rem;
  }
  @include min(780){
    margin-bottom: 3rem;
    background-color: white;
    padding: 1rem 3rem 3rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    align-self: flex-start;
    justify-self: flex-start;
    order: -1;
    width: 100%;
    box-sizing: border-box;
    margin-left: 0;
  }

  .avatar{
    height: 10rem;
    width: 11rem;
    color: white;
    padding-top: 4rem;
    display: flex;
    justify-content: center;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 0.2rem;

    span{
      padding: 0;
      height: fit-content;
      font-size: 3.8rem;
      letter-spacing: 0.08em;
      margin-right: -0.08em;
      font-family: "Manrope", sans-serif;
      font-weight: 500;
    }
  }

  .name{
    font-weight: 700;
    font-family: "Manrope", sans-serif;
    font-size: 2rem;
    margin-bottom: 1.8rem;
    @include min(780){
      font-size: 2.8rem;
    }
  }

  .break{
    height: 2px;
    background-color: #CCCCCC;
    margin-bottom: 2rem;
    margin-top: 2rem;
    width: 60%;
    @include min(780){
      width: 70%;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    &:last-of-type{
      margin-bottom: 2.5rem;
    }
  }

  .text,
  .link{
    font-size: 1.6rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    line-height: 24px;
    @include min(780){
      text-align: center;
      font-size: 1.6rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .link{
    color: #0d00ff;
    border-bottom: 1px transparent;
    &:hover{
      border-bottom: 1px solid #0d00ff;
    }
    @include min(780){
      display: none;
    }
  }
}

.AccountSidePanelAdmin{
  @include min(780){
    margin-bottom: 0;
    margin-top: 3rem;
    order: 1;
  }
}