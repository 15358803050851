.TopBar{
  background-color: #FFFFFF;
  width: 100vw;
  position: sticky;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 8888;
  border-bottom: 1px solid #CCCCCC;

  .inner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    position: relative;
  }

  .DropDownTray{
    position: absolute;
    background-color: white;
    right: 0;
    top: calc(100% + 1px);
    width: calc(100vw - 4vw);
    max-width: 350px;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 5px 10px -1px rgba(0,0,0,0.3);
  }

  .Logo{
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    cursor: pointer;
  }
}