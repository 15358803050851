//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.select_wrapper{
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 4.5rem;
  box-sizing: border-box;
  display: block;
  border: none;
  font-weight: normal;
  position: relative;
  font-size: 18px;
  @include min(330){
    font-size: 16px;
  }

  select{
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    border: solid 1px #757675;
    -webkit-appearance: none;
    cursor: pointer;
    height: 100%;
    width: 100%;
    border-radius: 0;
    &:focus,
    select:focus{
      outline-offset: 0;
      border: solid 1px #ff8c00;
      outline: 2px solid #ff8c00;
      -webkit-appearance: none;
    }
  }
}

.select_display_text{
  padding-left: 1rem;
  background-color: white;
  font-family: 'Roboto', sans-serif;
  outline: none;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  padding-top: 1.13rem;
  pointer-events: none;
  padding-right: 4rem;
  white-space: nowrap;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  @include min(330){
    font-size: 16px;
  }

  .select_chevron{
    position: absolute;
    right: 1.4rem;
    top: 1.7rem;
    height: 1.3rem;
    width: 1.8rem;
    img{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

.select_display_text_bold{
  font-weight: 500;
}

.select_wrapper_disabled{
  pointer-events: none;
  opacity: 0.8;
  select{
    outline: solid 1px #757675;
  }
  .select_display_text{
    color: #757675;
  }
}