.traySpeedMedium {
  transition-duration: 0.5s!important;
}

.traySpeedFast {
  transition-duration: 250ms!important;
}

.tray_container{
  padding-bottom: 1px;
  margin-bottom: -1px;
}