//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .spinner {
    position: absolute;
    height: 8rem;
    width: 8rem;
    text-indent: -9999em;
    animation: spin 1s infinite linear;
    @include min(930){
      height: 7rem;
      width: 7rem;
    }
    @include min(400){
      height: 6.5rem;
      width: 6.5rem;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}