.AddButton{
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  color: #1ebc30;
  box-shadow: 0 0 0 2px #1ebc30 inset, 0 0 0 0 transparent;
  background-color: #e5f9e7;
  border: none;
  padding: 0.45em 1.9em 0.45em 1em;
  display: inline-flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;

  span{
    position: absolute;
    right: 0.55em;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0;

    img{
      display: block;
      width: 1.2em;
      height: 1.2em;
    }

  }

  &:hover{
    background-color: white;
  }
}