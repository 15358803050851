//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.AccountSidePanel{
  width: calc(33.33% - 4rem);
  margin-left: 4rem;
  @include min(900){
    width: 24%;
    margin-left: 3rem;
  }
  @include min(780){
    margin-bottom: 3rem;
    background-color: white;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    align-self: flex-start;
    justify-self: flex-start;
    order: -1;
    width: 100%;
    box-sizing: border-box;
    margin-left: 0;
  }

  .title{
    font-weight: 700;
    font-family: "Manrope", sans-serif;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .break{
    height: 2px;
    background-color: #CCCCCC;
    margin-bottom: 2rem;
    margin-top: 2rem;
    width: 60%;
    @include min(780){
      width: 70%;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    &:last-of-type{
      margin-bottom: 2.5rem;
    }
  }

  .text{
    font-size: 1.6rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include min(780){
      font-size: 1.6rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}