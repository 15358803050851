//Mixins
@mixin min($width){
    @media (max-width: $width + px){
        @content
    }
}

.table_container{
    width: 100%;
    padding-bottom: 2rem;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: auto !important;
    @include min(550){
        width: calc(100% + 15px);
        box-sizing: border-box;
        max-width: none;
    }
    .table_container_padding{
        width: fit-content;
        min-width: 100%;
        @include min(550){
            padding-right: 15px;
        }
    }
}

table {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    text-align: left;
    font-size: 1.6rem;
    background-color: white;
    border-collapse: collapse;
    white-space: nowrap;
    @include min(400){
        font-size: 1.4rem;
    }
}

.min_cell_height td{
    height: 3rem;
}

table td, #table th {
    border: 1px solid #ddd;
    padding: 0.9rem 1.2rem 0.9rem 1.2rem;
}

table tr:nth-child(even){background-color: #f2f2f2;}

table th, tfoot td {
    font-family: "Manrope", sans-serif;
    padding: 0.9rem 1.2rem 0.9rem 1.2rem;
    background-color: #8C1500;
    color: white;
    font-size: 1.6rem;
    white-space: nowrap;
    @include min(400){
        font-size: 1.4rem;
    }
}

table th:last-of-type,
td:last-of-type{
    text-align: center;
}

button.table_link{
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    color: #0d00ff;
    border: none;
    border-bottom: 1px solid transparent;
    &:hover{
        border-bottom: 1px solid #0d00ff;
    }
}

.table_badge {
    font-family: "Roboto", sans-serif;
    font-size: 1.4rem;
    color: #1ebc30;
    box-shadow: 0 0 0 2px #1ebc30 inset, 0 0 0 0 transparent;
    background-color: #e5f9e7;
    border: none;
    padding: 0.45em 1em 0.45em 1em;
    display: inline-flex;
    flex-direction: row;
    position: relative;
    white-space: nowrap;
}

.table_badge_with_button {
    padding: 0.45em 1.9em 0.45em 1em;
}

.remove_button {
    position: absolute;
    top: 50%;
    right: 0.6rem;
    transform: translate(0, -50%);
    cursor: pointer;
    height: 1.8rem;
    width: 1.8rem;
    border: 1px transparent;
    margin-left: 0.2rem;
    color: #1ebc30;
    padding: 0;
    font-size: 1.2rem;
    line-height: 1.6rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 50px;
    background-color: transparent;
}
.remove_button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}


.search{
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 4rem;
    box-sizing: border-box;
    display: block;
    outline: solid 1px #757675;
    outline-offset: -1px;
    border: none;
    font-weight: normal;
    -webkit-appearance: none;
    margin-bottom: 2rem;
    width: 33.333%;
    &:focus,
    select:focus{
        outline: 3px solid #ff8c00;
        -webkit-appearance: none;
    }
    @include min(600){
        width: 50%;
    }
    @include min(450){
        width: 100%;
    }
}

.table_nav{
    font-size: 1.4rem;
    height: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2rem;
    white-space: nowrap;

    > *{
        margin-right: 2rem;
        @include min(1060){
            margin-right: 1.5rem;
        }
    }

    .page_of_page_text{
        @include min(600){
            display: none;
        }
    }

    .go_to_page_text{
        margin-right: 0;
        @include min(1060){
            display: none;
        }
    }

    .page_search{
        font-family: 'Roboto', sans-serif;
        font-size: 1.4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        height: 3rem;
        box-sizing: border-box;
        outline: solid 1px #757675;
        outline-offset: -1px;
        border: none;
        font-weight: normal;
        -webkit-appearance: none;
        width: 5rem;
        text-align: center;
        margin-left: 1rem;
        @include min(1060){
            display: none;
        }
    }

    .show_number{
        @include min(450){
            display: none;
        }
    }

    .nav_search_buttons{
        > *{
            margin-right: 0.5rem;
        }
    }

}