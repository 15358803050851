//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.courseWrapper{
  display: flex;
  flex-wrap: wrap;
  @include min(1090){
    width: 100%;
  }
}

.threeCoursesSpace{
  padding-right: 5rem;
  @include min(1150){
    padding-right: 0;
  }
}