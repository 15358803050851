//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.back{
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  background-color: transparent;
  white-space: nowrap;
  width: fit-content;
  border: none;
  display: flex;
  padding: 0.5em 0.5em 0.5em 0;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid transparent;
  margin-top: 3rem;
  color: black;
  @include min(800){
    margin-top: 2rem;
  }
  @include min(550){
    margin-top: 1.5rem;
  }

  &:hover{
  }

  span{
    height: 1.7rem;
    width: 1rem;
    display: block;
    margin-right: 1rem;
    margin-bottom: 0.3rem;

    img{
      width: 100%;
      height: 100%;
    }
  }
}