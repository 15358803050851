.Message{
  color: #1ebc30;
  box-shadow: 0 0 0 2px #1ebc30 inset, 0 0 0 0 transparent;
  background-color: #e5f9e7;
  padding: 2rem;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.4em;
  margin-bottom: 2rem;
}

.orange{
  color: black;
  box-shadow: 0 0 0 2px #ff8c00 inset, 0 0 0 0 transparent;
  background-color: #fff3e6;
}