//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.sub_menu_page{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;

  .sub_menu_body{
    width: 100%;
    max-width: calc(80% - 5rem);
    position: relative;
    @include min(1150){
      max-width: calc(75% - 5rem);
    }
    @include min(1100){
      max-width: calc(75% - 3rem);
    }
    @include min(860){
      max-width: 100%;
    }
  }
}