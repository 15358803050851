.modal_buttons_container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  margin-top: 3rem;

  .deleteButton{
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    color: #db2828;
    box-shadow: 0 0 0 2px #db2828 inset, 0 0 0 0 transparent;
    background-color: #ffe8e6;
    border: none;
    padding: 0.45em 1em 0.45em 1em;
    display: inline-flex;
    flex-direction: row;
    position: relative;
    cursor: pointer;
    &:hover{
      background-color: white;
    }
  }

  .break{
    flex: 1;
    min-height: 3rem;
    max-width: 1px;
    background-color: #0d00ff;
    margin-left: 2rem;
    margin-right: 1rem;
    display: inline-block;
  }

  .altButton{
    height: fit-content;
    color: #0d00ff;
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    transform: translate(0, 0.05rem);
    border-bottom: 1px solid transparent;
    &:hover{
      border-bottom: 1px solid #0d00ff;
    }
  }
}
