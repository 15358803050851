.update_grade_container{
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  line-height: 4rem;

  table{
    font-size: 1.6rem!important;
  }
}