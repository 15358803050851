/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('/assets/fonts/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* manrope-700 - latin */
@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/manrope-v13-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/manrope-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/manrope-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/manrope-v13-latin-700.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/manrope-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/manrope-v13-latin-700.svg#Manrope') format('svg'); /* Legacy iOS */
}
/* manrope-800 - latin */
@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    src: url('/assets/fonts/manrope-v13-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/manrope-v13-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/manrope-v13-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/manrope-v13-latin-800.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/manrope-v13-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/manrope-v13-latin-800.svg#Manrope') format('svg'); /* Legacy iOS */
}
/* playfair-display-regular - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/playfair-display-v30-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/playfair-display-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/playfair-display-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/playfair-display-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/playfair-display-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/playfair-display-v30-latin-regular.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-600 - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/playfair-display-v30-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/playfair-display-v30-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/playfair-display-v30-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/playfair-display-v30-latin-600.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/playfair-display-v30-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/playfair-display-v30-latin-600.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-900 - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 900;
    src: url('/assets/fonts/playfair-display-v30-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/playfair-display-v30-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/playfair-display-v30-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/playfair-display-v30-latin-900.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/playfair-display-v30-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/playfair-display-v30-latin-900.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-italic - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/playfair-display-v30-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/playfair-display-v30-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/playfair-display-v30-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/playfair-display-v30-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/playfair-display-v30-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/playfair-display-v30-latin-italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-800italic - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 800;
    src: url('/assets/fonts/playfair-display-v30-latin-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/playfair-display-v30-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/playfair-display-v30-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/playfair-display-v30-latin-800italic.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/playfair-display-v30-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/playfair-display-v30-latin-800italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* calligraffitti-regular - latin */
@font-face {
    font-family: 'Calligraffitti';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/calligraffitti-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('/assets/fonts/calligraffitti-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/assets/fonts/calligraffitti-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/assets/fonts/calligraffitti-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/assets/fonts/calligraffitti-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/assets/fonts/calligraffitti-v19-latin-regular.svg#Calligraffitti') format('svg'); /* Legacy iOS */
}