.mailing_list_container{
  min-height: 30vh;
  padding: 2.5rem 3rem 2.5rem 3rem;
  margin-bottom: 3rem;
  position: relative;

  textarea{
    font-family: 'Roboto', sans-serif;
    font-size: 1.4rem;
    line-height: 1.4em;
    padding: 2rem;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    resize: none;
    border: 1px solid black;
    border-radius: 0;
    &:focus,
    select:focus{
      border: 1px solid #ff8c00;
      outline: 2px solid #ff8c00;
    }
  }

  .noMailingList{
    font-family: "Manrope", sans-serif;
    font-size: 2rem;
    color: #c9c9c9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }


}