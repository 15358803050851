//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.certificate-thumbnail {
  border: solid 1px #CCCCCC;
  background-size: 100%;
  background-repeat: no-repeat;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: transparent;
  cursor: pointer;
  transition: transform 250ms;
  width: calc(25% - 8px - 1rem);
  padding: 0;
  margin-bottom: 2rem;
  margin-right: 2rem;
  position: relative;

  &:nth-child(4n) {
    margin-right: 0;
  }

  @include min(1165) {
    width: calc(33.3333% - 6px - 1rem);

    &:nth-child(4n) {
      margin-right: 2rem;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @include min(875) {
    width: calc(33.3333% - 6px - 0.6rem);
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
    &:nth-child(4n){
      margin-right: 1.5rem;
    }
  }

  @include min(780) {
    width: calc(33.3333% - 6px - 1rem);
    margin-bottom: 2rem;
    margin-right: 2rem;
    &:nth-child(4n){
      margin-right: 2rem;
    }
  }

  @include min(620) {
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
    &:nth-child(4n){
      margin-right: 1.5rem;
    }
  }

  @include min(550) {
    width: calc(33.3333% - 6px - 6px);
    margin-bottom: 15px;
    margin-right: 15px;
    &:nth-child(4n){
      margin-right: 15px;
    }
  }

  @include min(475) {
    width: calc(50% - 4px - 6px);

    &:nth-child(3n) {
      margin-right: 15px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  &__background {
    width: 50%;
    display: block;
  }

  &__inner {
    position: absolute;
    top: 34%;
    bottom: 22%;
    left: 13%;
    right: 13%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;

    &__weapons-system {
      font-family: "Playfair Display", "Times New Roman", Times, serif;
      font-weight: 700;
      color: black;
      display: inline-block;
      line-height: 1.2em;
      font-size: 1.65rem;
      margin-bottom: 0.3em;
      @include min(1450){
        font-size: 1.15vw;
      }
      @include min(1165){
        font-size: 1.6vw;
      }
      @include min(900) {
        font-size: 1.8vw;
      }
      @include min(780) {
        font-size: 2.4vw;
      }
      @include min(550) {
        font-size: 2.7vw;
      }
      @include min(475) {
        font-size: 3.9vw;
      }

      &__covid-text {
        margin-top: -3px;
        margin-bottom: -7px;
      }
    }

    &__exam-date {
      font-family: "Playfair Display", "Times New Roman", Times, serif;
      color: black;
      font-size: 1.4rem;
      line-height: 1em;
      @include min(1450){
        font-size: 0.95vw;
      }
      @include min(1165){
        font-size: 1.2vw;
      }
      @include min(900) {
        font-size: 1.3vw;
      }
      @include min(780) {
        font-size: 1.8vw;
      }
      @include min(550) {
        font-size: 2.1vw;
      }
      @include min(475) {
        font-size: 3vw;
      }
    }
  }

  &:hover {
    transform: scale(1.06);
  }

  img {
    width: 100%;
  }
}