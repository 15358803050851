//Datepicker
.react-datepicker {
  font-family: 'Roboto', sans-serif!important;
  font-size: 1.3rem;
  border: 1px solid #757675;
  border-radius: 0;

  .react-datepicker__header{
    background-color: #eeeeee;
  }

  .react-datepicker__current-month {
    font-family: 'Manrope', sans-serif;
    font-size: 1.8rem;    }

  .react-datepicker__header {
    padding-top: 1.3rem;
  }
  .react-datepicker__navigation {
    top: 1rem;
  }
  .react-datepicker__day--selected{
    background-color: #8C1500!important;
    border-radius: 100px!important;
  }

  .react-datepicker__day-name, .react-datepicker__day {
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .react-datepicker__day--keyboard-selected{
    border-radius: 0;
    background-color: #eeeeee;
    color: black;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 0;
    background-color: #eeeeee;
  }
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  left: -3.5rem;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: #eeeeee;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #eeeeee;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 0;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #757675;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  bottom: 0;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #757675;
}