//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.AppIconContainer{
  margin-bottom: 2.5rem;
  padding: 0;
  color: black;
  display: inline-block;
  width: 100%;
  max-width: calc(16.666% - 1px);
  @include min(1440){
    max-width: calc(20% - 1px);
  }
  @include min(1067){
    max-width: calc(25% - 1px);
  }
  @include min(418){
    max-width: calc(33% - 1px);
  }

  .AppIcon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    border: none;
    padding: 0;
    font-size: 1.4rem;
    line-height: 1em;
    cursor: pointer;
    background-color: transparent;
    text-align: center;
    margin: auto;
    max-width: 10rem;
    @include min(1090){
      width: 80%;
      margin: auto;
    }

    &:hover{
      .background{
        transform: scale(1.08);
      }
    }

    .background {
      height: 6rem;
      width: 100%;
      margin-bottom: 1rem;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: transform 100ms;
      @include min(400){
        height: 5rem;
      }
      .image {
        top: 0;
        position: absolute;
        height: 100%;
      }
    }
  }

  .date {
    margin-top: 3px;
    font-weight: 500;
  }
}

.right_aligned{
  margin-left: auto;
}

.IconLine{
  display: inline-block;
  background-color: #CCCCCC;
  width: 1px;
  height: 9rem;
  margin-top: 0.5rem;

  &:nth-of-type(6n){
    display: none;
    @include min(1440){
      display: block;
    }
    @include min(1067){
      display: block;
    }
  }

  &:nth-of-type(5n){
    @include min(1440){
      display: none;
    }
    @include min(1067){
      display: block;
    }
  }

  &:nth-of-type(4n){
    @include min(1067){
      display: none;
    }
    @include min(418){
      display: block;
    }
  }

  &:nth-of-type(3n){
    @include min(418){
      display: none;
    }
  }

  &:last-of-type{
    display: none;
  }

  @include min(800){
    height: 5rem;
    margin-top: 2rem;
  }
}