.toggleSwitch{
  font-family: 'Roboto', sans-serif;
  font-size: 1.4rem;
  cursor: pointer;
  display: flex;
  border: none;
  margin: 0 0 0 2rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 0.5rem;
  height: fit-content;

  .switchContainer{
    display: block;
    height: 3rem;
    width: 6rem;
    background-color: #ff8c00;
    border-radius: 5px;
    position: relative;

    .offBackground{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 5px;
      background-color: #ddd;
      transform: scale(0);
      transition: transform 300ms;
    }

    .knob{
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15), 0 2px 3px 0 rgba(0, 0, 0, 0.15);
      position: absolute;
      top: 1px;
      left: calc(100% - 3rem + 1px);
      display: block;
      height: calc(3rem - 2px);
      width: calc(3rem - 2px);
      background-color: white;
      border-radius: 5px;
      transition: 300ms;
    }
  }
}

.switchOff{
  .switchContainer {

    .offBackground {
      transform: scale(1);
      transition: transform 300ms;
    }

    .knob {
      left: 1px;
    }
  }
}