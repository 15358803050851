//Mixins
@mixin min($width){
	@media (max-width: $width + px){
		@content
	}
}

.monthSection {
	margin: 30px 20px;
	border: 1px solid #CCCCCC;
	padding: 30px 10px 0;
	border-radius: 6px;
	position: relative;
	background-color: rgba(238, 238, 238, 0.6);
	@include min(550) {
		margin: 30px 10px;
	}

	&__title {
		font-size: 1.8rem;
		border-radius: 6px;
		background-color: white;
		position: absolute;
		top: -14px;
		left: 10px;
		padding: 4px 16px;
		font-style: italic;
		white-space: nowrap;

		@include min(550) {
			left: 50%;
			transform: translate(-50%);
		}
	}
}