//Mixins
@mixin min($width){
  @media (max-width: $width + px){
    @content
  }
}

.modal{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  padding: 6rem 0 6rem 0;
  flex-direction: column;
  @include min(900){
    padding: 4rem 0 4rem 0;
  }

  .modal_backdrop{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 500ms;
  }

  .modal_content_scroll{
    margin: auto;
    position: relative;
    width: 100vw;
    pointer-events: none;

    .modal_content{
      pointer-events: auto;
      min-height: 20vh;
      border-radius: 5px;
      background-color: white;
      width: calc(100vw - 30px);
      box-sizing: border-box;
      max-width: 800px;
      font-size: 1.6rem;
      padding: 3rem 3rem 5rem 3rem;
      margin: auto;
      position: relative;
      opacity: 0;
      transition: opacity 400ms, transform 400ms;
      transform: translate(0, -100px);
      @include min(600){
        width: 100vw;
      }
      @include min(900){
        padding: 2rem;
      }
      @include min(600){
        padding: 15px;
      }

      .modal_close{
        cursor: pointer;
        height: 2.4rem;
        width: 2.4rem;
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        background-position: center;
        background-size: 80%;
        background-repeat: no-repeat;
        border: 0;
        transform: translate(115%, -140%);
        @include min(900){
          transform: translate(-15%, -135%);
        }

        img{
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

      }

      p{
        line-height: 1.3em;
      }

      .form{
        margin: 0;
        padding: 0;
        display: block;
      }
    }

    .modal-no-padding{
      padding: 0;
    }
  }

  &__print{
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    text-align: center;
    padding-top: 1rem;
    button{
      cursor: pointer;
      padding: 0;
      display: inline;
      border: none;
      background-color: transparent;
      font-size: 1.8rem;
      font-family: "Manrope", sans-serif;
      color: white;
      border-bottom: 0.1em solid white;

      @include min(750) {
        font-size: 1.6rem;
      }

      @include min(600) {
        font-size: 1.4rem;
      }
    }
  }
}

.enter-active,
.enter-done{
  .modal_backdrop{
    background-color: rgba(0, 0, 0, 0.9);
  }

  .modal_content_scroll{
    .modal_content{
      opacity: 1;
      transform: scale(1);
    }
  }
}